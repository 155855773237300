import dayjs from 'dayjs';
import { isBoolean } from 'lodash';

import { EEventZoomMeetingRole } from '@/constants/meetup';

export const checkEventLiveStatus = (
	meetup: Pick<IMeetup, 'zoomMeetingId' | 'period' | 'isLive'>,
	meetupLiveStatusBySocket?: boolean
): boolean => {
	if (dayjs().isAfter(dayjs(meetup.period.start).subtract(15, 'm'))) {
		if (meetup.zoomMeetingId) {
			return isBoolean(meetupLiveStatusBySocket) ? meetupLiveStatusBySocket : meetup.isLive;
		}
		return dayjs().isBefore(meetup.period.end);
	}
	return false;
};

export const shouldShowMeetingBtn = (
	hasAccess: boolean,
	meetup: Pick<IMeetup, 'zoomMeetingId' | 'period' | 'isLive' | 'liveStreamingLink'>
): boolean => {
	if (hasAccess && meetup) {
		if (dayjs().isBefore(meetup.period.end)) {
			if (dayjs().isAfter(dayjs(meetup.period.start).subtract(15, 'm'))) {
				if (meetup.zoomMeetingId) {
					return true;
				}
				return !!meetup.liveStreamingLink;
			}
		} else {
			if (meetup.zoomMeetingId) {
				return meetup.isLive;
			}
			return false;
		}
	}
	return false;
};

export const getMeetingRole = (userId: string, meetup: TMeetupDetail) => {
	if (!meetup.zoomMeetingId) {
		return EEventZoomMeetingRole.ATTENDEE;
	}
	if (meetup.host === userId) {
		return EEventZoomMeetingRole.HOST;
	}
	if (meetup.speakers?.some(speaker => speaker.user === userId)) {
		return EEventZoomMeetingRole.SPEAKER;
	}
	if (meetup.guests?.includes(userId)) {
		return EEventZoomMeetingRole.GUEST;
	}
	return EEventZoomMeetingRole.ATTENDEE;
};

export default {};
