import Image from 'next/image';
import styled from 'styled-components';

import { devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.div`
	align-items: center;
	background-color: #eee;
	border-radius: 10px;
	display: flex;
	font-size: 12px;
	gap: 6px;
	padding: 4px 8px;
	width: fit-content;
	@media ${devices.laptop} {
		padding: 4px 10px;
		gap: 8px;
		font-size: 14px;
	}
`;
const AppliedImage = styled(Image)`
	height: 12px;
	width: 12px;
	@media ${devices.laptop} {
		width: 14px;
		height: 14px;
	}
`;

/**
 * 报名状态tag
 */
const MeetupAppliedStatusTag = ({ status }: { status: 'applied' | 'not_applied' }) => {
	return (
		<Container>
			{status === 'applied' && (
				<AppliedImage
					src="/image/common/applied.svg"
					alt=""
					width={0}
					height={0}
					loader={imageLoader}
					unoptimized
				/>
			)}
			{status === 'applied' && '已报名'}
			{status === 'not_applied' && '未报名'}
		</Container>
	);
};

export default MeetupAppliedStatusTag;
