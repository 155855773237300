/**
 * 简单无色的小icon
 * 预览效果见http://localhost:6006/?path=/docs/stories-images--docs
 */
export enum SIMPLE_ICON {
	CALENDAR = '/image/common/calendar.svg',
	LOCATION = '/image/common/location.svg',
	TYPE = '/image/common/type.svg',
	NOTE = '/image/common/note.svg',
	TICKET = '/image/common/ticket.svg'
}

export default {};
