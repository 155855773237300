import dayjs from 'dayjs';
import Image from 'next/image';
import styled from 'styled-components';

import TicketNumText from '../Meetup/TicketNumText';

import { SIMPLE_ICON } from '@/constants/dict';
import { MEETUP_TYPE, MEETUP_TYPE_ICONS, MEETUP_TYPE_TEXT } from '@/constants/meetup';
import { textEllipsis } from '@/styles/mixin';
import { devices } from '@/styles/variables';
import { formatDurationDHM } from '@/utils/date';
import imageLoader from '@/utils/loader';
import { getTicketPrices } from '@/utils/price';

const Container = styled.div<{ isBorderShow: boolean }>`
	align-items: center;
	display: flex;
	gap: 6px;
	@media ${devices.tablet} {
		min-height: 30px;
		border-bottom: ${props => props.isBorderShow && '1px dotted #666'};
		:first-of-type {
			border-top: ${props => props.isBorderShow && '1px dotted #666'};
		}
	}
`;
const IconImage = styled(Image)`
	display: none;
	@media ${devices.tablet} {
		display: block;
	}
`;
const Item = styled.p`
	align-items: center;
	display: flex;
	${textEllipsis(1)}
	font-size: 12px;
	margin: 0;
	width: 100%;
	@media ${devices.tablet} {
		font-size: 14px;
	}
`;
const ItemContainer = styled.div`
	width: 60px;
`;
const Bold = styled.p`
	display: inline;
	font-weight: 600;
	line-height: 1;
	margin: 0;
	white-space: nowrap;
`;

const InfoItem = ({
	icon,
	startTime,
	endTime,
	meetupType,
	liveStreaming,
	address,
	tickets,
	remainingNum = 0,
	totalNum = 0,
	isBorderShow = true
}: {
	icon: SIMPLE_ICON;
	startTime?: Date;
	endTime?: Date;
	meetupType?: MEETUP_TYPE;
	liveStreaming?: string;
	address?: string;
	tickets?: IMeetupTicket[];
	remainingNum?: number;
	totalNum?: number;
	isBorderShow?: boolean;
}) => {
	const getAlt = () => {
		const regex = /\/([^/]+)\.svg$/;
		const match = icon.match(regex);
		if (match && match.length > 2) {
			return match[1];
		}
		return '';
	};

	const renderContent = () => {
		switch (icon) {
			case SIMPLE_ICON.CALENDAR:
				if (!startTime || !endTime) {
					return '';
				}
				return (
					<Item>
						{dayjs(startTime).format('YYYY/MM/DD HH:mm')}&nbsp;
						<Bold>{`${formatDurationDHM(
							dayjs(endTime).diff(startTime, 'second')
						)}`}</Bold>
					</Item>
				);
			case SIMPLE_ICON.LOCATION:
				if (!meetupType) {
					return '';
				}
				return <Item>{meetupType === MEETUP_TYPE.ONLINE ? liveStreaming : address}</Item>;
			case SIMPLE_ICON.TYPE:
				if (!meetupType) {
					return '';
				}
				return (
					<>
						<ItemContainer>
							<Item>{MEETUP_TYPE_TEXT[meetupType as MEETUP_TYPE]}</Item>
						</ItemContainer>
						{MEETUP_TYPE_ICONS[meetupType] && (
							<Image
								src={MEETUP_TYPE_ICONS[meetupType]}
								alt=""
								width={60}
								height={20}
								loader={imageLoader}
								unoptimized
							/>
						)}
					</>
				);
			case SIMPLE_ICON.NOTE:
				if (!tickets) {
					return '';
				}
				return <Item>{getTicketPrices(tickets)}</Item>;
			case SIMPLE_ICON.TICKET:
				return <TicketNumText remain={remainingNum} total={totalNum} />;
			default:
				return '';
		}
	};

	return (
		<Container isBorderShow={isBorderShow}>
			<IconImage
				src={icon}
				alt={getAlt()}
				width={14}
				height={14}
				unoptimized
				loader={imageLoader}
			/>
			{renderContent()}
		</Container>
	);
};

export default InfoItem;
