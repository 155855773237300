import styled from 'styled-components';

import { textEllipsis } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';

const Item = styled.p`
	align-items: center;
	display: flex;
	${textEllipsis(1)}
	font-size: 12px;
	margin: 0;
	width: 100%;
	@media ${devices.tablet} {
		font-size: 14px;
	}
`;
const Bold = styled.p`
	display: inline;
	font-weight: 600;
	line-height: 1;
	margin: 0;
`;
const Red = styled.p`
	color: ${color.primaryColor};
	display: inline;
	font-weight: 600;
	line-height: 1;
	margin: 0;
`;

/**
 * 余票数量文本
 */
const TicketNumText = ({ remain, total }: { remain: number; total: number }) => {
	const render = () => {
		if (remain <= 10) {
			return (
				<Item>
					仅剩名额&nbsp;
					<Red>{remain}/</Red>
					{total}
				</Item>
			);
		}
		if (total / 2 >= remain) {
			return (
				<Item>
					仅剩名额&nbsp;
					<Bold>{remain}</Bold>/{total}
				</Item>
			);
		}
		return (
			<Item>
				名额&nbsp;<Bold>{remain}</Bold>/{total}
			</Item>
		);
	};
	return render();
};

export default TicketNumText;
