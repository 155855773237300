export enum EMeetupEventType {
	JIANGREN = 'jiangren'
}

export enum EMeetupRecommendPlace {
	RECOMMEND = 'recommend'
}

export enum MEETUP_LIST_TYPE {
	INGOING = 'ingoing',
	COMING = 'coming',
	PAST = 'past'
}

export enum MEETUP_TYPE {
	ONLINE = 'online',
	OFFLINE = 'offline'
}

export enum LIVE_STREAMING_OPTIONS {
	ZOOM = 'zoom',
	GOOGLE_MEET = 'meet',
	MICROSOFT_TEAMS = 'teams',
	OTHER = 'other'
}

export const MEETUP_TYPE_TEXT = {
	[MEETUP_TYPE.ONLINE]: '线上活动',
	[MEETUP_TYPE.OFFLINE]: '线下活动'
};

export enum MEETUP_PRICE_TYPE {
	FREE = 'free',
	PRICED = 'paid'
}

export const MEETUP_TYPE_ICONS = {
	[MEETUP_TYPE.OFFLINE]: '/image/event/offline.svg',
	[MEETUP_TYPE.ONLINE]: '/image/event/online.svg'
};

/**
 * 活动类型,用于查询
 */
export enum EVENT_TYPE {
	/** 匠人活动 */
	JR = 'jr',
	/** hackathon */
	HACKATHON = 'hackathon'
}

/**
 * 匠人活动类型
 */
export enum JR_EVENT_TYPE {
	/** 讲座 */
	LECTURE = 'lecture',
	/** 公开课 */
	PUBLIC_COURSE = 'publicCourse',
	/** 学员采访 */
	STUDENT_INTERVIEW = 'studentInterview',
	/** 大学活动 */
	UNIVERSITY_ACTIVITY = 'universityActivity',
	/** 职场面经 */
	WORK_EXPERIENCE = 'workExperience',
	/** WORKSHOP */
	WORKSHOP = 'workshop',
	/** 招聘会 */
	CAREER_FAIR = 'careerFair',
	/** 编程挑战 */
	HACKATHON = 'hackathon'
}

export const JR_EVENT_TYPE_TEXT: Record<JR_EVENT_TYPE, string> = {
	[JR_EVENT_TYPE.LECTURE]: '讲座',
	[JR_EVENT_TYPE.PUBLIC_COURSE]: '公开课',
	[JR_EVENT_TYPE.STUDENT_INTERVIEW]: '学员采访',
	[JR_EVENT_TYPE.UNIVERSITY_ACTIVITY]: '大学活动',
	[JR_EVENT_TYPE.WORK_EXPERIENCE]: '职场面经',
	[JR_EVENT_TYPE.WORKSHOP]: 'Workshop',
	[JR_EVENT_TYPE.CAREER_FAIR]: '招聘会',
	[JR_EVENT_TYPE.HACKATHON]: '编程挑战'
};

export enum MEETUP_SHARE_IMAGE_TYPE {
	/** 布里斯班专场 */
	BRISBANE = 'brisbane',
	/** 墨尔本专场 */
	MELBOURNE = 'melbourne',
	/** 悉尼专场 */
	SYDNEY = 'sydney',
	/** 阿德莱德专场 */
	ADELAIDE = 'adelaide',
	/** 求职讲座 */
	JOB_LECTURE = 'jobLecture',
	/** 求职故事分享 */
	JOB_STORY = 'jobStory',
	/** 干货讲座 */
	PRACTICAL_LECTURE = 'practicalLecture',
	/** 职场分享会 */
	CAREER_SHARE = 'careerShare',
	/** 公开课 */
	OPEN_CLASS = 'openClass'
}

export enum EEventZoomMeetingRole {
	HOST = 'host',
	SPEAKER = 'speaker',
	GUEST = 'guest',
	ATTENDEE = 'attendee'
}

export const JOIN_EVENT_MEETING_BUTTON_TEXT = {
	[EEventZoomMeetingRole.HOST]: '主持人开启直播',
	[EEventZoomMeetingRole.SPEAKER]: '嘉宾入口',
	[EEventZoomMeetingRole.GUEST]: '嘉宾入口',
	[EEventZoomMeetingRole.ATTENDEE]: '进入直播'
};

export default {};
