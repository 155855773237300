import Image from 'next/image';
import styled from 'styled-components';

import { JR_EVENT_TYPE, JR_EVENT_TYPE_TEXT } from '@/constants/meetup';
import useMeetupCountDown from '@/hooks/useMeetupCountDown';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;
	left: 6px;
	position: absolute;
	top: 6px;
	z-index: 1;
`;
const JrEventTypeTag = styled.div`
	display: none;
	@media ${devices.tablet} {
		display: block;
		background-color: #ffe400;
		border: solid 1px #000;
		border-radius: 1px;
		font-size: 14px;
		line-height: 1;
		padding: 6px 8px;
	}
`;
const Tag = styled.div`
	background-color: #ffe400;
	border: solid 1px #000;
	border-radius: 1px;
	font-size: 10px;
	line-height: 1;
	padding: 4px;
	@media ${devices.tablet} {
		padding: 6px 8px;
		font-size: 14px;
	}
`;
const RedTag = styled.div`
	align-items: center;
	background-color: ${color.primaryColor};
	border: solid 1px #000;
	border-radius: 1px;
	color: ${color.whiteColor};
	display: flex;
	font-size: 10px;
	gap: 4px;
	line-height: 1;
	padding: 4px;
	@media ${devices.tablet} {
		gap: 24px;
		font-size: 14px;
		padding: 6px 8px;
	}
`;
const IngoingContentStart = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;
	span {
		display: none;
		@media ${devices.tablet} {
			display: unset;
		}
	}
`;
const ImageIngoing = styled(Image)`
	display: none;
	@media ${devices.tablet} {
		width: 12px;
		height: 12px;
		display: flex;
	}
`;
const IngoingImage = styled(Image)`
	animation: blink 1s linear infinite;
	height: 10px;
	opacity: 1;
	width: 10px;
	@media ${devices.tablet} {
		width: 12px;
		height: 12px;
	}
	@keyframes blink {
		0%,
		100% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
	}
`;

interface MeetupCardHeaderProps {
	startTime: Date;
	jrEventType?: JR_EVENT_TYPE;
	isLive: boolean;
}

const MeetupCardHeader = ({ startTime, jrEventType, isLive }: MeetupCardHeaderProps) => {
	const { remainingTime, timeText } = useMeetupCountDown({ startTime });
	const renderIngoingContent = () => {
		return (
			<RedTag>
				<IngoingContentStart>
					{jrEventType ? JR_EVENT_TYPE_TEXT[jrEventType] : ''}
					正在进行中…
					<ImageIngoing
						src="/image/common/ingoing.svg"
						alt=""
						width={0}
						height={0}
						unoptimized
						loader={imageLoader}
					/>
				</IngoingContentStart>
				<IngoingImage
					src="/image/common/ingoing-video.svg"
					alt=""
					width={0}
					height={0}
					unoptimized
					loader={imageLoader}
				/>
			</RedTag>
		);
	};
	const renderElseContent = () => {
		return (
			<>
				{jrEventType ? (
					<JrEventTypeTag>{JR_EVENT_TYPE_TEXT[jrEventType]}</JrEventTypeTag>
				) : (
					''
				)}

				{remainingTime !== -1 ? (
					<Tag>
						活动倒计时：<b>{timeText}</b>
					</Tag>
				) : (
					''
				)}
			</>
		);
	};
	return <Container>{isLive ? renderIngoingContent() : renderElseContent()}</Container>;
};

export default MeetupCardHeader;
