import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useEffect, useState } from 'react';

dayjs.extend(duration);

interface CountdownProps {
	startTime: Date;
	showCountdownDays?: boolean;
}
const useMeetupCountDown = ({ startTime, showCountdownDays = false }: CountdownProps) => {
	const [remainingTime, setRemainingTime] = useState<number>(-1);
	const [timeText, setTimeText] = useState('');

	const shouldShowCountdown =
		showCountdownDays ||
		(dayjs(startTime).diff(dayjs(), 'hour') < 24 && dayjs().isBefore(startTime));

	useEffect(() => {
		if (shouldShowCountdown) {
			const timer = setInterval(() => {
				const now = new Date().getTime();
				const distance = new Date(startTime).getTime() - now;

				if (distance <= 0) {
					clearInterval(timer);
					setRemainingTime(-1);
					setTimeText('00:00:00');
				} else {
					setRemainingTime(distance);
					const remainingDuration = dayjs.duration(distance);
					const days = remainingDuration.days();
					const hours = remainingDuration.hours();
					const minutes = remainingDuration.minutes();
					const seconds = remainingDuration.seconds();

					const pad = (num: number) => (num < 10 ? '0' : '') + num;
					const mainTimeText = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
					const fullTimeText =
						showCountdownDays && days > 0 ? `${days}d ${mainTimeText}` : mainTimeText;
					setTimeText(fullTimeText);
				}
			}, 1000);

			return () => {
				clearInterval(timer);
			};
		}
		return () => '';
	}, [startTime]);
	return { timeText, remainingTime };
};

export default useMeetupCountDown;
