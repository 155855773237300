export const getTicketPrices = (tickets: IMeetupTicket[]) => {
	let maxPrice: number = -1;
	let minPrice: number = -1;
	if (tickets && tickets.length > 0) {
		tickets.forEach(ticket => {
			if (maxPrice === -1 || ticket.price > maxPrice) {
				maxPrice = ticket.price;
			}
			if (minPrice === -1 || ticket.price < minPrice) {
				minPrice = ticket.price;
			}
		});
		if (maxPrice === 0) {
			return '免费';
		}
		if (maxPrice === minPrice) {
			return `A$${maxPrice}`;
		}
		return `A$${minPrice} - A$${maxPrice}`;
	}
	return '免费';
};

export default {};
